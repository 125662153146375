<template>
  <div id="error">
    <div class="card">
      <img :src="require('../assets/images/icons/png/lock.png')" alt="Lock" />
      <div class="content-wrapper">
        <h2>Whooops!</h2>
        <p>
          You are not authorized for this content. Please contact your
          supervisor or sign in as a different user.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" />

<style scoped lang="stylus">
#error
  height calc(100vh - 120px)
  page-container()
  flex()
  .card
    flex()
    width 430px
    border-radius 8px
    padding 40px 50px 60px
    background white
    img
      width 60px
      margin-top 42px
      margin-right 15px
    .content-wrapper
      paddding 15px
      h2
        margin-bottom 15px
      p
        font-size $font-size-lg2x
</style>
